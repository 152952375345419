// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-blog-post-page-en-js": () => import("./../../../src/layouts/Blog/post-page-en.js" /* webpackChunkName: "component---src-layouts-blog-post-page-en-js" */),
  "component---src-layouts-blog-post-page-fr-js": () => import("./../../../src/layouts/Blog/post-page-fr.js" /* webpackChunkName: "component---src-layouts-blog-post-page-fr-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogue-js": () => import("./../../../src/pages/blogue.js" /* webpackChunkName: "component---src-pages-blogue-js" */),
  "component---src-pages-bornes-tactiles-js": () => import("./../../../src/pages/bornes-tactiles.js" /* webpackChunkName: "component---src-pages-bornes-tactiles-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-contenu-js": () => import("./../../../src/pages/contenu.js" /* webpackChunkName: "component---src-pages-contenu-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-contact-us-js": () => import("./../../../src/pages/en/contact-us.js" /* webpackChunkName: "component---src-pages-en-contact-us-js" */),
  "component---src-pages-en-content-js": () => import("./../../../src/pages/en/content.js" /* webpackChunkName: "component---src-pages-en-content-js" */),
  "component---src-pages-en-hardware-js": () => import("./../../../src/pages/en/hardware.js" /* webpackChunkName: "component---src-pages-en-hardware-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-industries-eyewear-and-optic-js": () => import("./../../../src/pages/en/industries/eyewear-and-optic.js" /* webpackChunkName: "component---src-pages-en-industries-eyewear-and-optic-js" */),
  "component---src-pages-en-industries-factory-js": () => import("./../../../src/pages/en/industries/factory.js" /* webpackChunkName: "component---src-pages-en-industries-factory-js" */),
  "component---src-pages-en-industries-financial-institution-js": () => import("./../../../src/pages/en/industries/financial-institution.js" /* webpackChunkName: "component---src-pages-en-industries-financial-institution-js" */),
  "component---src-pages-en-industries-internals-communications-and-human-ressources-js": () => import("./../../../src/pages/en/industries/internals-communications-and-human-ressources.js" /* webpackChunkName: "component---src-pages-en-industries-internals-communications-and-human-ressources-js" */),
  "component---src-pages-en-industries-js": () => import("./../../../src/pages/en/industries.js" /* webpackChunkName: "component---src-pages-en-industries-js" */),
  "component---src-pages-en-industries-municipality-and-tourism-js": () => import("./../../../src/pages/en/industries/municipality-and-tourism.js" /* webpackChunkName: "component---src-pages-en-industries-municipality-and-tourism-js" */),
  "component---src-pages-en-industries-others-industries-js": () => import("./../../../src/pages/en/industries/others-industries.js" /* webpackChunkName: "component---src-pages-en-industries-others-industries-js" */),
  "component---src-pages-en-industries-pharmacy-js": () => import("./../../../src/pages/en/industries/pharmacy.js" /* webpackChunkName: "component---src-pages-en-industries-pharmacy-js" */),
  "component---src-pages-en-industries-restauration-and-entertainment-js": () => import("./../../../src/pages/en/industries/restauration-and-entertainment.js" /* webpackChunkName: "component---src-pages-en-industries-restauration-and-entertainment-js" */),
  "component---src-pages-en-industries-retail-business-js": () => import("./../../../src/pages/en/industries/retail-business.js" /* webpackChunkName: "component---src-pages-en-industries-retail-business-js" */),
  "component---src-pages-en-pricing-js": () => import("./../../../src/pages/en/pricing.js" /* webpackChunkName: "component---src-pages-en-pricing-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-queue-management-js": () => import("./../../../src/pages/en/queue-management.js" /* webpackChunkName: "component---src-pages-en-queue-management-js" */),
  "component---src-pages-en-software-js": () => import("./../../../src/pages/en/software.js" /* webpackChunkName: "component---src-pages-en-software-js" */),
  "component---src-pages-en-touch-screens-js": () => import("./../../../src/pages/en/touch-screens.js" /* webpackChunkName: "component---src-pages-en-touch-screens-js" */),
  "component---src-pages-equipement-js": () => import("./../../../src/pages/equipement.js" /* webpackChunkName: "component---src-pages-equipement-js" */),
  "component---src-pages-gestion-de-file-dattente-js": () => import("./../../../src/pages/gestion-de-file-dattente.js" /* webpackChunkName: "component---src-pages-gestion-de-file-dattente-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logiciel-js": () => import("./../../../src/pages/logiciel.js" /* webpackChunkName: "component---src-pages-logiciel-js" */),
  "component---src-pages-nous-parler-js": () => import("./../../../src/pages/nous-parler.js" /* webpackChunkName: "component---src-pages-nous-parler-js" */),
  "component---src-pages-politique-de-confidentialite-js": () => import("./../../../src/pages/politique-de-confidentialite.js" /* webpackChunkName: "component---src-pages-politique-de-confidentialite-js" */),
  "component---src-pages-secteurs-autres-secteurs-js": () => import("./../../../src/pages/secteurs/autres-secteurs.js" /* webpackChunkName: "component---src-pages-secteurs-autres-secteurs-js" */),
  "component---src-pages-secteurs-commerce-de-detail-js": () => import("./../../../src/pages/secteurs/commerce-de-detail.js" /* webpackChunkName: "component---src-pages-secteurs-commerce-de-detail-js" */),
  "component---src-pages-secteurs-communications-internes-et-ressources-humaines-js": () => import("./../../../src/pages/secteurs/communications-internes-et-ressources-humaines.js" /* webpackChunkName: "component---src-pages-secteurs-communications-internes-et-ressources-humaines-js" */),
  "component---src-pages-secteurs-institution-financiere-js": () => import("./../../../src/pages/secteurs/institution-financiere.js" /* webpackChunkName: "component---src-pages-secteurs-institution-financiere-js" */),
  "component---src-pages-secteurs-js": () => import("./../../../src/pages/secteurs.js" /* webpackChunkName: "component---src-pages-secteurs-js" */),
  "component---src-pages-secteurs-lunetterie-et-optique-js": () => import("./../../../src/pages/secteurs/lunetterie-et-optique.js" /* webpackChunkName: "component---src-pages-secteurs-lunetterie-et-optique-js" */),
  "component---src-pages-secteurs-municipalite-et-tourisme-js": () => import("./../../../src/pages/secteurs/municipalite-et-tourisme.js" /* webpackChunkName: "component---src-pages-secteurs-municipalite-et-tourisme-js" */),
  "component---src-pages-secteurs-pharmacie-js": () => import("./../../../src/pages/secteurs/pharmacie.js" /* webpackChunkName: "component---src-pages-secteurs-pharmacie-js" */),
  "component---src-pages-secteurs-restauration-et-divertissement-js": () => import("./../../../src/pages/secteurs/restauration-et-divertissement.js" /* webpackChunkName: "component---src-pages-secteurs-restauration-et-divertissement-js" */),
  "component---src-pages-secteurs-usine-js": () => import("./../../../src/pages/secteurs/usine.js" /* webpackChunkName: "component---src-pages-secteurs-usine-js" */),
  "component---src-pages-tarifs-js": () => import("./../../../src/pages/tarifs.js" /* webpackChunkName: "component---src-pages-tarifs-js" */)
}

